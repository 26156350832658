import { render, staticRenderFns } from "./processMark.vue?vue&type=template&id=42764364&scoped=true&"
import script from "./processMark.vue?vue&type=script&lang=js&"
export * from "./processMark.vue?vue&type=script&lang=js&"
import style0 from "./processMark.vue?vue&type=style&index=0&id=42764364&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42764364",
  null
  
)

export default component.exports